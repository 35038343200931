/**
 * Объявление при открытии сайта
 */

let isStorageSupport = true;
let storedNoticeDate = ``;
try {
  storedNoticeDate = localStorage.getItem(`bp_notice_date`);
} catch (err) {
  isStorageSupport = false;
}

const divNotice = document.querySelector(`.notice`);
if (divNotice) {
  const noticeDate = divNotice.querySelector(`.notice__section`).dataset.date;

  const showPopUp = (popup) => {
    popup.hidden = false;
    document.addEventListener(`keydown`, onDocumentKeydown);
  };

  const hidePopUp = (popup) => {
    popup.hidden = true;
    localStorage.setItem(`bp_notice_date`, noticeDate);
    document.removeEventListener(`keydown`, onDocumentKeydown);
  };

  const onDocumentKeydown = (evt) => {
    evt.preventDefault();
    if ((evt.key === `Escape`) || (evt.key === `Enter`)) {
      hidePopUp(divNotice);
    }
  };

  if (isStorageSupport) {
    if (storedNoticeDate === noticeDate) {
      hidePopUp(divNotice);
    } else {
      showPopUp(divNotice);
    }
  }

  divNotice.addEventListener(`click`, (evt) => {
    evt.preventDefault();
    hidePopUp(divNotice);
  });
}
