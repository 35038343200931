import $ from 'jquery';

/**
 * Подсветка столбца таблицы при наведении
*/

$(`tbody td, thead th:not([colspan])`).on(`mouseenter mouseleave`, function () {
  const $cell = $(this);

  if ($cell.closest(`table`).hasClass(`table--poor`)) {
    return;
  }

  const rowHeadCells = $cell.closest(`table`).children(`tbody`).children().first().children(`th`).length;

  const getShifts = (tag) => {
    const $rows = $cell.closest(`table`).children(tag).children();
    const mapOfRowShifts = new Array($rows.length).fill(0);
    $rows.each((rowIndx, row) => {
      $(row).children().each((cellIndx, cell) => {
        const rowspan = +$(cell).attr(`rowspan`);
        const colspan = +$(cell).attr(`colspan`);
        for (let i = rowIndx + 1; i < rowIndx + rowspan; i++) { // для rowspan строк ниже текущей
          mapOfRowShifts[i]++; // добавляем одно смещение
          if (colspan > 1) {
            mapOfRowShifts[i] += colspan - 1; // добавляем (colspan-1) смещение
          }
        }
      });
    });
    return mapOfRowShifts;
  };

  const mapShifts = (shift) => {
    let index = $cell.index() - shift;
    if ($cell.closest(`thead`).length > 0) {
      index += theadShifts[$cell.parent().index()];
    } else {
      index += tbodyShifts[$cell.parent().index()];
    }
    return index;
  };

  const theadShifts = getShifts(`thead`);
  const tbodyShifts = getShifts(`tbody`);

  const tbodyCellIndexes = tbodyShifts.map(mapShifts);
  const theadCellIndexes = theadShifts.map(mapShifts);

  tbodyCellIndexes.forEach(function (col, row) {
    if (col > (rowHeadCells - 1 - tbodyShifts[row])) {
      $($($cell.closest(`table`).children(`tbody`).children()[row]).children()[col]).toggleClass(`highlighted`);
    }
  });

  theadCellIndexes.forEach(function (col, row) {
    if (col > (rowHeadCells - 1 - theadShifts[row])) {
      $($($cell.closest(`table`).children(`thead`).children()[row]).children(`th:not([colspan])`)[col]).toggleClass(`highlighted`);
    }
  });

});
