/* eslint-disable require-jsdoc */
class Banner {
  constructor(selector) {
    this.banner = document.querySelector(selector);
    this.wrap = this.banner.querySelector(`.banner__wrap`);
    this.title = this.banner.querySelector(`.banner__title`);
    this.slogan = this.banner.querySelector(`.banner__slogan`);
    this.widthBeforeResize = window.innerWidht;
    window.addEventListener(`load`, this.correct.bind(this));
    window.addEventListener(`resize`, this.correct.bind(this));
    window.addEventListener(`scroll`, this.stickWrap.bind(this));
  }
  correctHeight(evt) {
    if (evt.type === `resize` && this.widthBeforeResize === window.innerWidht) return;
    const vh = window.innerHeight;
    const localMenu = document.querySelector(`.page__local-menu`);
    const pageHeader = document.querySelector(`.page__header`);
    let minHeight = vh;
    if (localMenu) {
      minHeight = vh - localMenu.offsetHeight - pageHeader.offsetHeight;
    };
    minHeight = Math.max(minHeight, this.wrap.offsetHeight);
    this.banner.style.minHeight = `${minHeight}px`;
    this.banner.style.backgroundSize = `auto ${vh}px`;
    this.widthBeforeResize = window.innerWidht;
  }
  correctFontSizes() {
    this.title.style.fontSize = ``;
    this.slogan.style.fontSize = ``;
    if (document.documentElement.clientWidth > 576) return;

    const titleFontSize = getComputedStyle(this.title).fontSize.slice(0, -2);
    const sloganFontSize = getComputedStyle(this.slogan).fontSize.slice(0, -2);

    const clientWidth = document.documentElement.clientWidth;

    const titleScale = this.title.scrollWidth /
      (clientWidth - 40);
    const sloganScale = this.slogan.scrollWidth /
      (clientWidth - 40);
    const scale = (titleScale > sloganScale) ? titleScale : sloganScale;

    this.title.style.fontSize = titleFontSize / scale + `px`;
    this.slogan.style.fontSize = sloganFontSize / scale + `px`;
  }
  stickWrap() {
    if ((this.banner.offsetHeight - this.wrap.offsetHeight) >
      window.pageYOffset) {
      this.wrap.style.position = `fixed`;
      this.wrap.style.top = `auto`;
      this.wrap.style.bottom = ``;
    } else {
      this.wrap.style.position = `absolute`;
      this.wrap.style.bottom = 0;
    }
  }
  correct(evt) {
    this.correctFontSizes();
    this.correctHeight(evt);
    this.stickWrap();
  }
}

if (document.querySelector(`.banner`)) {
  new Banner(`.banner`);
}
