const TABLE_SELECTOR = `.table--alterable`;

const alterableTables = document.querySelectorAll(TABLE_SELECTOR);

for (const table of alterableTables) {
  const mainHeadCellIndex = table.tBodies[0].children[0].querySelectorAll(`th`).length - 1;
  table.mainHeadCellIndex = mainHeadCellIndex;
}

const redesignTable = (table) => {
  if (table.classList.contains(`table--altered`)) {
    return;
  }

  table.classList.add(`table--altered`);
  const bodyRows = table.querySelectorAll(`tbody tr`);
  const headRows = table.querySelectorAll(`thead tr`);
  const headFirstRow = headRows[0];
  const headLastRow = headRows[headRows.length - 1];
  if (headFirstRow.cells[0].colSpan > 1) {
    headFirstRow.cells[0].colSpan--;
    headLastRow.insertAdjacentHTML(`afterbegin`, `<th hidden></th>`);
  } else {
    headFirstRow.cells[0].hidden = true;
  }

  for (const bodyrow of bodyRows) {
    const row = document.createElement(`tr`);
    const cell = bodyrow.cells[table.mainHeadCellIndex];
    cell.colSpan = bodyrow.cells.length - 1;
    row.className = `table__row-head`;
    row.prepend(cell.cloneNode(true));
    cell.hidden = true;
    bodyrow.before(row);
  }
};

const restoreTable = (table) => {
  if (!(table.classList.contains(`table--altered`))) {
    return;
  }

  table.classList.remove(`table--altered`);
  const bodyRows = table.querySelectorAll(`tbody tr`);
  const headRows = table.querySelectorAll(`thead tr`);
  const headFirstRow = headRows[0];
  const headLastRow = headRows[headRows.length - 1];

  if (headFirstRow.cells[0].hidden) {
    headFirstRow.cells[0].hidden = false;
  } else {
    headFirstRow.cells[0].colSpan++;
    headLastRow.cells[0].remove();
  }
  for (const bodyrow of bodyRows) {
    if (bodyrow.className === `table__row-head`) {
      const nextRow = bodyrow.nextSibling;
      nextRow.cells[table.mainHeadCellIndex].colSpan = 0;
      nextRow.cells[table.mainHeadCellIndex].hidden = false;
      bodyrow.remove();
    }
  }
};

const checkTables = () => {
  if (document.documentElement.clientWidth < 768) {
    for (const table of alterableTables) {
      redesignTable(table);
    }
  } else {
    for (const table of alterableTables) {
      restoreTable(table);
    }
  }
};

checkTables();
window.addEventListener(`resize`, checkTables);
