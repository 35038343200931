/* eslint-disable max-len */
/* eslint-disable require-jsdoc */
/* eslint-disable no-invalid-this */
import $ from 'jquery';

const mainNavNavbar = document.querySelector(`.main-nav__navbar`);
const toggler = document.querySelector(`.main-nav__toggler`);
const mainMenu = document.querySelector(`.main-menu`);
const banner = document.querySelector(`.page__banner`);
const header = document.querySelector(`.page__header`);
const titleWrap = document.querySelector(`.page__title-wrap`);

const moreButtonHTML = `<li class="folder folder--more main-menu__item">
  <button class="main-menu__link folder__toggle" type="button">. . .</button>
  <div class="folder__wrap">
    <ul class="folder__menu">
    </ul>
  </div>
</li>`;
mainMenu.insertAdjacentHTML(`beforeend`, moreButtonHTML);

const moreMenuItemsFolder = document.querySelector(`.folder--more`);

const mainMenuItems = document.querySelectorAll(`.main-menu > .main-menu__item:not(.folder--more)`);

const allMenuItems = document.querySelectorAll(`.main-menu__item`);

const redirectEvent = (evt, target) => {
  if (!target) {
    return;
  }
  const eventType = evt.type;
  evt.stopPropagation();
  const event = new MouseEvent(eventType);
  target.dispatchEvent(event);
};

const resetMainMenu = () => {
  let firstFoldedItem = moreMenuItemsFolder.querySelector(`.main-menu__item`);
  while (firstFoldedItem) {
    resetMenuItem(firstFoldedItem, moreMenuItemsFolder);
    firstFoldedItem = moreMenuItemsFolder.querySelector(`.main-menu__item`);
  }
  moreMenuItemsFolder.style.display = `none`;
};

const formatHiddenFolders = (folders) => {
  for (const folder of folders) {
    // $(folder).off(`click mouseenter focus`);

    folder.removeEventListener(`click`, onFolderClickMouseenterFocus);
    folder.removeEventListener(`mouseenter`, onFolderClickMouseenterFocus);
    folder.removeEventListener(`focus`, onFolderClickMouseenterFocus);
    folder.removeEventListener(`mouseleave`, onFolderMouseleave);

    $(folder).children(`.folder__wrap`).removeAttr(`style`);
    $(folder).children(`button`).attr(`disabled`, `true`);
    $(folder).addClass(`main-menu__item--hover`);
  }
};

const sliceMainMenu = () => {
  resetMainMenu();
  const visibleToggler = !((getComputedStyle(toggler).display === `none`) || toggler.hidden);
  if (visibleToggler) {
    return;
  }

  let overflovedIndex = mainMenuItems.length - 1;

  while (mainNavNavbar.scrollWidth > (mainNavNavbar.clientWidth)) {
    if (overflovedIndex < 2) {
      return;
    }
    moreMenuItemsFolder.querySelector(`.folder__menu`).prepend(mainMenuItems[overflovedIndex]);
    overflovedIndex--;
  }

  formatHiddenFolders(moreMenuItemsFolder.querySelectorAll(`.folder`));
  moreMenuItemsFolder.style.display = (moreMenuItemsFolder.querySelector(`.main-menu__item`)) ? `` : `none`;
};

function addFolderListeners(folder) {
  folder.addEventListener(`click`, onFolderClickMouseenterFocus);
  folder.addEventListener(`mouseenter`, onFolderClickMouseenterFocus);
  folder.addEventListener(`focus`, onFolderClickMouseenterFocus);
  folder.addEventListener(`mouseleave`, onFolderMouseleave);
}

function resetMenuItem(firstFoldedItem, folderMore) {
  if (firstFoldedItem.classList.contains(`folder`)) {
    addFolderListeners(firstFoldedItem);
    $(firstFoldedItem).children(`button`).removeAttr(`disabled`);
    $(firstFoldedItem).removeClass(`main-menu__item--hover`);
  }
  folderMore.before(firstFoldedItem);
}

function unfold($folder) {
  const h = $folder.children(`.folder__wrap`).children(`.folder__menu`).outerHeight(true);
  $folder.children(`.folder__wrap`).addClass(`folder__wrap--show`);
  $folder.children(`.folder__wrap`).height(h);
  $folder.addClass(`folder--opened`);
}

function fold($folder) {
  $folder.children(`.folder__wrap`).removeClass(`folder__wrap--show`);
  $folder.children(`.folder__wrap`).height(0);
  $folder.children(`.folder__toggle`).blur();
  $folder.removeClass(`folder--opened`);
}

function correctSideOffset($folder) {
  if ($folder.hasClass(`folder--more`)) {
    return;
  }
  const $folderWrap = $folder.children(`.folder__wrap`);
  $folderWrap.offset({
    top: `auto`,
    left: $folder.offset().left,
  });

  const minOffset = 0;
  const offsetTop = $folderWrap.offset().top;
  const offsetLeft = $folderWrap.offset().left;
  const offsetRight = $(`.main-nav__navbar`).width() - offsetLeft - $folderWrap.width();
  if ((offsetLeft < minOffset) && (offsetRight > minOffset)) {
    $folderWrap.offset({
      top: offsetTop,
      left: minOffset,
    });
  }
  if ((offsetRight < minOffset) && (offsetLeft > minOffset)) {
    $folderWrap.offset({
      top: offsetTop,
      left: $(`.main-nav__navbar`).width() - $folderWrap.width() - minOffset,
    });
  }
}

function onFolderClickMouseenterFocus(evt) {

  correctSideOffset($(this));

  if (!((evt.type === `mouseenter`) && ($(`html`).hasClass(`touch-device`)))) {

    if ($(this).hasClass(`folder--opened`)) {
      fold($(this));
    } else {
      unfold($(this));
    }
  }
}

function onFolderMouseleave() {
  $(`.folder__wrap`).each(function (indx, element) {
    if (!($(element).parents(`.folder__wrap`).length > 0)) {
      $(element)
        // .css(`left`, `unset`)
        // .removeProp(`style`)
        .height(0)
        .removeClass(`folder__wrap--show`)
        .parents(`.folder`)
        .removeClass(`folder--opened`);
      $(this).children(`.folder__toggle`).blur();
    }
  });
}

function redirectClick(evt) {
  redirectEvent(evt, evt.target.querySelector(`.main-menu__link`));
}

const stickMainMenu = () => {
  if (banner) {
    if (banner.offsetHeight < window.pageYOffset) {
      header.style.position = `relative`;
      header.style.top = banner.offsetHeight + `px`;
    } else {
      header.style = ``;
    }
  } else {
    if (titleWrap.offsetHeight < window.pageYOffset) {
      header.style.position = `relative`;
      header.style.top = titleWrap.offsetHeight + `px`;
    } else {
      header.style = ``;
    }
  }
};

for (const item of allMenuItems) {
  if (item.classList.contains(`folder`)) {
    continue;
  }
  item.addEventListener(`click`, redirectClick);
}

for (const folder of document.querySelectorAll(`.folder`)) {
  addFolderListeners(folder);
}

window.addEventListener(`resize`, sliceMainMenu);
window.addEventListener(`load`, sliceMainMenu);

if (banner || titleWrap) {
  window.addEventListener(`scroll`, stickMainMenu);
}
