import 'lazysizes';
import 'bootstrap/js/dist/util';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/scrollspy';
import 'bootstrap/js/dist/alert';
import './notice.js';
import './table/table-highlight.js';
import './table/table-redesign.js';
import './viz-calculator.js';
import './main-menu.js';
// import "./dropdown.js";
import './banner.js';
import './tel-mask.js';


/**
 * Проверка на тач-пад
 */

document.body.addEventListener(`touchstart`, () => {
  document.querySelector(`html`).classList.add(`touch-device`);
});

/**
 *  Активизация main-menu__item
 */
const navLinks = document.querySelectorAll(`.main-menu__link`);
for (const element of navLinks) {
  if (element.classList.contains(`active`)) {
    delete element.href;
    element.parentElement.classList.add(`active`);
  }
}

/**
 * Плавная прокрутка по якорной ссылке
 */

const anchors = document.querySelectorAll(`.local-menu [href*="#"]`);

for (const anchor of anchors) {
  anchor.addEventListener(`click`, function(e) {
    e.preventDefault();

    const blockID = anchor.getAttribute(`href`);

    document.querySelector(blockID).scrollIntoView({
      behavior: `smooth`,
      block: `start`,
    });
  });
}
