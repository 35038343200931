/* eslint-disable require-jsdoc */
class TelMask {
  constructor(options) {
    this.el = options.el;
    if (!this.el) return;
    this.layout = options.layout || `+7 (___) ___-__-__`;
    this.maskreg = this.getRegexp();

    this.setListeners();
  }
  getRegexp() {
    let str = this.layout.replace(/_/g, `\\d`);
    str = str.replace(/\(/g, `\\(`);
    str = str.replace(/\)/g, `\\)`);
    str = str.replace(/\+/g, `\\+`);
    str = str.replace(/\s/g, `\\s`);

    return str;
  }
  mask(e) {
    const _this = e.target;
    const matrix = this.layout;
    let i = 0;
    const def = matrix.replace(/\D/g, ``);
    let val = _this.value.replace(/\D/g, ``);

    if (def.length >= val.length) {
      val = def;
    }

    _this.value = matrix.replace(/./g, function(a) {
      return /[_\d]/.test(a) && i < val.length ? val.charAt(i++) : i >= val.length ? `` : a;
    });

    if (e.type === `blur`) {
      const regexp = new RegExp(this.maskreg);
      if (!regexp.test(_this.value)) {
        _this.value = ``;
      }
    } else {
      this.setCursorPosition(_this.value.length, _this);
    }
  }
  setCursorPosition(pos, elem) {
    elem.focus();
    if (elem.setSelectionRange) {
      elem.setSelectionRange(pos, pos);
    } else if (elem.createTextRange) {
      const range = elem.createTextRange();
      range.collapse(true);
      range.moveEnd(`character`, pos);
      range.moveStart(`character`, pos);
      range.select();
    }
  }
  setListeners() {
    this.el.addEventListener(`input`, this.mask.bind(this), false);
    this.el.addEventListener(`focus`, this.mask.bind(this), false);
    this.el.addEventListener(`blur`, this.mask.bind(this), false);
  }
}

const inputs = document.querySelectorAll(`input[name$="[tel]"]`);
if (inputs) {
  const InputMasks = [];
  for (const input of inputs) {
    InputMasks.push(
        new TelMask({
          el: input,
          layout: input.attributes.placeholder.nodeValue,
        })
    );
  }
}
