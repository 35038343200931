import $ from 'jquery';

const calcForm = document.querySelector(`.webform-client-form-74`);

let price = {};

function renderVizPrices(data) {
  let html = `<optgroup label="Цифровая печать">`;
  let entries = Object.entries(data[`1`][`digital`]);
  for (let entrie of entries) {
    html += `<option value="${entrie[0]}" data-metod="digital">${entrie[0]}</option>`;
  }
  html += `<optgroup label="Офсетная печать">`;
  entries = Object.entries(data[`1`][`offset`]);
  for (let entrie of entries) {
    html += `<option value="${entrie[0]}" data-metod="offset">${entrie[0]}</option>`;
  }
  return html;
}

function renderCartonPrices(data) {
  let html = ``;
  let key = 1;
  let entries = Object.entries(data);
  for (let entrie of entries.values()) {
    html += `<option value="${key}" data-carton="${entrie[0]}">${entrie[1].name}</option>`;
    key++;
  }
  return html;
}

function renderPriceElements() {
  document.querySelector(`#edit-submitted-parameters-amount`).innerHTML = renderVizPrices(price.vizPrices);
  document.querySelector(`#edit-submitted-parameters-carton`).innerHTML = renderCartonPrices(price.cartonPrices);
}

function calcPrice() {
  let {designPrice, shippingPrice, vizPrices, cartonPrices} = price;

  const design = $(`#edit-submitted-option :radio:checked`).val();
  const metod = $(`#edit-submitted-parameters-amount option:selected`).data(`metod`);
  const amount = $(`#edit-submitted-parameters-amount option:selected`).text();
  const carton = $(`#edit-submitted-parameters-carton option:selected`).data(`carton`);
  const cartonName = $(`#edit-submitted-parameters-carton option:selected`).text();
  const sides = $(`#edit-submitted-parameters-sides :radio:checked`).val();
  const rounded = $(`#edit-submitted-parameters-rounded :checkbox`).prop(`checked`);
  const shipping = $(`#edit-submitted-order-get-option :radio:checked`).val();

  const description = $(`.webform-component--parameters--amount .description`);
  description.text(`* офсетная печать одного макета`);

  $(`#edit-submitted-parameters-amount .description`).text();
  let printCost = vizPrices[sides][metod][amount];

  if (metod === `digital`) {
    const cartonSelector = document.querySelector(`#edit-submitted-parameters-carton`);
    for (const option of cartonSelector.querySelectorAll(`option`)) {
      option.disabled = false;
    }
    printCost += cartonPrices[carton][amount] - cartonPrices[`cristalBoard`][amount];
    if (rounded) {
      printCost += +amount;
    }
    description.text(`* цифровая печать` + ((+amount > 100) ? (` до ` + Math.round(amount / 100) + ` разных макетов`) : ``));
  } else {
    const cartonSelector = document.querySelector(`#edit-submitted-parameters-carton`);
    cartonSelector.selectedIndex = 0;
    for (const option of cartonSelector.querySelectorAll(`option`)) {
      if (option.value > 1) {
        option.disabled = true;
      }
    }
  }

  if (design === `design`) {
    printCost += designPrice;
  }
  if (shipping === `shipping`) {
    printCost += shippingPrice;
  }


  $(`.total__price`).text(printCost);
  $(`#edit-submitted-summa`).val(printCost);
  let summaryText = `<ul>`;
  summaryText += (sides === 1) ? `<li>визитки односторонние</li>` : `<li>визитки двусторонние</li>`;
  // summaryText += (design == 'design') ? '<li>верстка макета</li>' : '<li>макет заказчика</li>';
  summaryText += (metod === `digital`) ? `<li>цифровая печать</li>` : `<li>офсетная печать</li>`;
  summaryText += `<li>` + cartonName.toLowerCase() + `</li>`;
  summaryText += (rounded) ? `<li>скругление</li>` : ``;
  summaryText += (shipping === `shipping`) ? `<li>доставка</li>` : `<li>самовывоз</li>`;
  summaryText += `</ul>Тираж ${amount} шт.`;
  $(`.total__summary`).html(summaryText);
}

const checkValidation = () => {
  const submitButton = document.querySelector(`.webform .form-actions .form-submit`);

  const validatedMaket = ((document.querySelector(`[name="submitted[design][src][file][fid]"]`).value > 0) ||
    (document.querySelector(`#edit-submitted-design-information-text`).textLength > 0) ||
    (document.querySelector(`#edit-submitted-design-src-link`).value.length > 0));

  const validatedEmail = (document.querySelector(`#edit-submitted-customer-mail`).validity.valid);
  const validatedConsent = (document.querySelector(`#edit-submitted-customer-consent-1`).validity.valid);

  submitButton.disabled = !(validatedMaket && validatedEmail && validatedConsent);

  let validWarningHTML = `<span class="total__warning-item total__warning-item--${validatedMaket}">макет</span>`;
  validWarningHTML += ` <span class="total__warning-item total__warning-item--${validatedEmail}">e-mail</span>`;
  validWarningHTML += ` <span class="total__warning-item total__warning-item--${validatedConsent}">согласие</span>`;

  const validWarning = document.querySelector(`.total__warning`) || document.createElement(`p`);
  validWarning.className = `total__warning`;
  validWarning.innerHTML = validWarningHTML;

  const totalWarningWrap = document.querySelector(`.total__warning-wrap`);
  totalWarningWrap.insertAdjacentElement(`afterbegin`, validWarning);

  if (submitButton.disabled) {
    document.querySelector(`.total__warning`).classList.add(`total__warning--invalid`);
  } else {
    document.querySelector(`.total__warning`).classList.remove(`total__warning--invalid`);
  }
};

const onCalcOptionClick = () => {
  document.querySelector(`.webform`).classList.add(`webform--active`);
  const fieldSets = document.querySelectorAll(`.webform fieldset`);
  for (const fieldset of fieldSets) {
    fieldset.classList.remove(`disabled`);
    fieldset.disabled = false;
  }
};

const onCalcFormChange = () => {
  checkValidation();
  calcPrice();
};

const onDesignSrcMutation = (mutations) => {
  checkValidation();
  let addedForm = mutations.find((mutation) => mutation.target.className === `form-managed-file`);
  if (addedForm) {
    processFileInputs();
  }
};

const onFileInputChange = (evt) => {
  let mousedown = new MouseEvent(`mousedown`);
  evt.target.closest(`.form-managed-file`).querySelector(`.upload-button`).dispatchEvent(mousedown);
};

const processFileInput = (input) => {
  const uploadButton = input.querySelector(`.upload-button`);
  if (uploadButton) {
    uploadButton.style.display = `none`;
  }
  input.addEventListener(`change`, onFileInputChange);
};

const processFileInputs = () => {
  const fileInputs = document.querySelectorAll(`.form-managed-file`);
  for (const fileInput of fileInputs) {
    processFileInput(fileInput);
  }
};

if (calcForm) {

  fetch(`sites/belka-print.ru/themes/bs4theme/src.php`, {
    method: `POST`,
    body: `viz`,
  })
    .then((response) => response.json())
    .then((result) => {
      // parseData(result);
      price = result;
      renderPriceElements();
      calcPrice();
    });

  const calcOption = document.querySelector(`.webform-component--option`);

  calcOption.addEventListener(`click`, onCalcOptionClick, {once: true});

  calcForm.addEventListener(`keyup`, checkValidation);

  calcForm.addEventListener(`change`, onCalcFormChange);

  const observer = new MutationObserver(onDesignSrcMutation);
  observer.observe(calcForm.querySelector(`.webform-component--design--src`), {childList: true, subtree: true});

  checkValidation();

  processFileInputs();
}
